import React from "react";
import Layout from "../../Layout";
import "./style.scss";

export default function SubjectsPage() {
  return (
    <Layout>
      <div className="subjects">
        <h1 className="heading">Subjects</h1>
        <p>
          Chinmaya Vidyalaya offers the following class-wise subjects. Subject
          allocation in Class XI and XII is decided based on performance and
          aptitude, in conjunction with preference. Final subject allocation is
          at the discretion of the Management.
        </p>

        <div className="subjects__table">
          <div className="subjects__table__row subjects__table__row--heading">
            <div className="subjects__table__col1">Class</div>
            <div className="subjects__table__col2">Subjects</div>
          </div>

          <div className="subjects__table__row">
            <div className="subjects__table__col1">Pre-Primary</div>
            <div className="subjects__table__col2">
              Maths, Hindi, English, Art, General Awareness
            </div>
          </div>

          <div className="subjects__table__row">
            <div className="subjects__table__col1">I-III</div>
            <div className="subjects__table__col2">
              English, Hindi, Mathematics, General Awareness, Computers, Value
              Education
            </div>
          </div>
          <div className="subjects__table__row">
            <div className="subjects__table__col1">IV-V</div>
            <div className="subjects__table__col2">
              English, Hindi, Mathematics, General Awareness, Computers, Value
              Education, General Knowledge
            </div>
          </div>
          <div className="subjects__table__row">
            <div className="subjects__table__col1">VI-VIII</div>
            <div className="subjects__table__col2">
              English, Hindi, Mathematics, Science, Computer Science , Value
              Education, Social Science, Value Education, Sanskrit
            </div>
          </div>
          <div className="subjects__table__row">
            <div className="subjects__table__col1">IX-X</div>
            <div className="subjects__table__col2">
              English, Hindi, Mathematics, Science, IT, Value Education, Social
              Science, Value Education, Sanskrit
            </div>
          </div>

          <div className="subjects__table__row">
            <div className="subjects__table__col1">XI -XII</div>
            <div className="subjects__table__col2">
              English, Hindi, Maths, Physics, Chemistry, Biology, Accountancy,
              Business Studies, Computer Science, Psychology, Economics,
              Painting, Informatics Practices, Entrepreneurship, Political
              Science, Value Education, Physical Education
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
